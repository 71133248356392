<template>
	<div class="card card-custom card-stretch gutter-b">
		<div class="card-header border-0">
			<h3 class="card-title font-weight-bolder text-dark">{{ name }} - Web Performance Monitor</h3>
			<div class="d-flex align-items-center">
				<div class="ml-3">
					<b-button variant="outline-primary" size="sm" @click="generateCSV">Download CSV</b-button>
				</div>
			</div>
		</div>

		<div class="card-body pt-2">
			<ITCSpinner :loaded="loaded">
				<div v-if="error" class="pt-5">
					<b-alert show variant="warning" class="m-0">
						<span class="svg-icon-danger">
							<inline-svg src="/media/svg/icons/Code/Warning-2.svg"></inline-svg>
						</span>
						{{ errorMsg }}
					</b-alert>
				</div>
				<dygraphs v-else-if="dyData.length > 0" :data="dyData" :options="dyOpts"
			/></ITCSpinner>
		</div>
	</div>
</template>

<script>
const date = new Date();
const offset = date.getTimezoneOffset() * 60000;
import { downloadCSV } from '@/helpers';

export default {
	name: 'Webmonitor',
	props: ['name', 'id', 'updated'],
	components: {
		ITCSpinner: () => import('@/view/content/loaders/itcSpinner.vue'),
		dygraphs: () => import('@/view/content/lib/dygraphs.vue'),
	},
	data() {
		return {
			loaded: false,
			dyData: [],
			dyOpts: {},
			error: false,
			errorMsg: 'There was an error retrieving data for this device',
		};
	},
	methods: {
		load() {
			this.loaded = false;
			this.$http
				.get(`webmonitor/${this.id}`)
				.then(resp => {
					this.processData(resp.data.data);
					this.loaded = true;
				})
				.catch(e => {
					if (e.data && e.data == 'No data for selected range') {
						this.error = true;
						this.errorMsg = e.data;
					} else {
						this.errorMsg = 'There was an error retrieving data for this device:  ' + e.e;
						this.error = true;
					}
					this.loaded = true;
				});
		},
		processData(data) {
			if (!data.stats || data.stats.length == 0) {
				throw { data: data.stats, e: 'No data' };
			}
			var gd = [];
			Object.keys(data.stats).forEach(ts => {
				var o = data.stats[ts];

				var d = [new Date(ts * 1000 + offset)];
				o.forEach(p => {
					d.push(p);
				});
				gd.push(d);
			});
			this.dyData = gd;

			this.dyOpts = data.opts;
			this.dyOpts.axes = {
				y: {
					valueFormatter: function(y, opts, series) {
						return y + 's';
					},
				},
			};
		},
		generateCSV() {
			var csv = '';
			this.dyOpts.labels.forEach(l => {
				if (csv != '') {
					csv += ',';
				}
				csv += l;
			});
			csv += '\n';

			this.dyData.forEach(d => {
				var first = 1;
				d.forEach(a => {
					if (first) {
						csv +=
							a.toISOString().split('T')[0] +
							' ' +
							a
								.toISOString()
								.split('T')[1]
								.substring(0, 8);
					} else {
						csv += ',' + a;
					}
					first = 0;
				});
				csv += '\n';
				first = 1;
			});
			return downloadCSV(csv, this.name + ' - Web Monitor Stats - ' + new Date().toISOString().split('T')[0] + '.csv');
		},
	},
	watch: {
		updated() {
			this.load();
		},
	},
	created() {
		this.load();
	},
};
</script>

<style>
.footer {
	padding: 0px 30px 0px;
}
.pagination {
	float: right;
}
</style>
